import {Component} from 'react';
import axios from 'axios';
import Navbar from "../admin-navbar.component";
import "./notes.css";
import "./print.css"

const Note = props => (
    <tr className="printnote">
        <td className="date"><strong>{(new Date(props.note.date)).toLocaleString()}</strong></td>
        <td className="note">{props.note.topic}</td>
        <td className="note">{props.note.details}</td>
    </tr>
)

export default class NotesList extends Component {
    constructor(props){
        super(props);
        
        this.changeView = this.changeView.bind(this);
        this.pullNotes = this.pullNotes.bind(this);

        this.state= {
            notes: [],
            view: "Day",
            lastevent: ""
        }
    }

    changeView(e) {
        var view = "";
        if (e){
        if(this.state.lastevent !== "") {
            var last = this.state.lastevent.target
            last.className = "circle";
            
        }
        this.setState({
            view: e.target.name,
            lastevent: e
        });
        e.target.className = "view";
        view = e.target.name;
        sessionStorage.setItem('view', view);
        }
        else {
            view = sessionStorage.getItem('view');
        }
        console.log("View set "+view)
        if (view==="Tom") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+2);
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
        }
        else if (view==="Day") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+1);
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && new Date(obj.date)<=lastDate) });
        }
        else if (view==="Week") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+7);
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && new Date(obj.date) >= firstDate && new Date(obj.date)<=lastDate) });
        }
        else if (view==="Recuring") {
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && obj.nextTil >0) });
        }
        else if (view==="Archive") {
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.display) && obj.archive) });
        }
        else {
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.display) && !obj.archive) }); 
        }
    }
    
    componentDidMount(){
        if(localStorage.getItem('notes')!==""){
            console.log("Cause ")
            this.setState({notes: JSON.parse(localStorage.getItem("notes"))})
            var plan = JSON.parse(localStorage.getItem("notes")).filter(obj=> obj.display)[0];
            this.setState({plan: plan.topic, planid: plan._id });
        }
        console.log("View stored is "+sessionStorage.getItem('view'))
        if(sessionStorage.getItem('view')!==""){
            this.setState({view: sessionStorage.getItem('view')})
        }
        else {
            this.setState({view: "Day"})
            sessionStorage.setItem('view', "Day")
        }
        this.pullNotes() 
        if(sessionStorage.getItem('view')){
        setTimeout(()=>{
            document.getElementsByName(sessionStorage.getItem('view')).item(0).click();
            this.changeView()
        },500);        }
        else {
            setTimeout(()=>{
                document.getElementsByName("Day").item(0).click();
                this.changeView()
            },500); 
        }
    }

    notesList(){
        return this.state.notes.map(currentnote => {
            return <Note note={currentnote}/>
        });    
    }

    pullNotes(views){
        console.log("Being called");
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/", {owner: localStorage.getItem('token')})
        .then(response => {
            if (JSON.stringify(response.data)!==(localStorage.getItem("notes"))){  // forgot what this does
                console.log("Update")
                // this.setState({ notes: response.data });
                localStorage.setItem('notes', JSON.stringify(response.data));
                var plan = response.data.filter(obj=> obj.display)[0];
                this.setState({plan: plan.topic, planid: plan._id });
                // console.log(localStorage.getItem("notes"));
            }
            else {
                console.log("No Dif");
                this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
                plan = JSON.parse(localStorage.getItem("notes")).filter(obj=> obj.display)[0];
                this.setState({plan: plan.topic, planid: plan._id });
                // console.log(this.state.notes)
            } 
        })
        .catch((error) => {
            console.log(error);
       //     window.location = "/access";
        });
    }

    render(){
        return(
            <div>
            <Navbar />
            <table>
            <thead>
                        <tr>
                            <td>
                                <button type="button" name="All" className="circle" onClick={this.changeView}>All</button></td>
                                <td><button type="button" name="Day" className="circle" onClick={this.changeView}>Today</button></td>
                                <td><button type="button" name="Tom" className="circle" onClick={this.changeView}>Tomorrow</button></td>
                                <td><button type="button" name="Week" className="circle" onClick={this.changeView}>Week</button></td>
                                <td><button type="button" name="Recuring" className="circle" onClick={this.changeView}>Recuring</button></td>
                                <td><button type="button" name="Archive" className="circle" onClick={this.changeView}>Archive</button></td>
                        
                        </tr>
                    </thead>
                </table>
                
                <table className="table table-hover table-bordered print" >
                    <tbody>
                        {this.notesList()}
                    </tbody>
                </table>
                
            </div>
        )
    }
}