import React, { useState } from 'react';
import 'aframe';
import { Entity, Scene } from 'aframe-react';
import table from "./jmo.jpg";

const KitchenScene = () => {
  const [menuState, setMenuState] = useState({ open: false, item: null });

  const handleItemClick = (item) => {
    // Set both the open state and the clicked item
    setMenuState({ open: !menuState.open, item: item });
  };

  return (
    <Scene>
      {/* Floor */}
      <Entity primitive="a-plane" color="#FFF" height="10" width="10" rotation="-90 0 0"/>

      {/* Walls */}
      <Entity primitive="a-box" color="#FFD600" depth="0.1" height="3" width="10" position="0 1.5 -5"/>

      {/* Table */}
      <Entity primitive="a-box" src={table} color="brown" depth="2" height="0.1" width="3" position="0 1 -2"/>
      <Entity primitive="a-cylinder" color="brown" height="1" radius="0.1" position="1 0.5 -3"/>
      <Entity primitive="a-cylinder" color="brown" height="1" radius="0.1" position="-1 0.5 -3"/>
      <Entity primitive="a-cylinder" color="brown" height="1" radius="0.1" position="1 0.5 -1"/>
      <Entity primitive="a-cylinder" color="brown" height="1" radius="0.1" position="-1 0.5 -1"/>

      {/* Realistic Appetizer */}
      <Entity primitive="a-cylinder" color="#F5F5DC" radius="0.3" height="0.02" position="-0.5 1.1 -1.5" onClick={() => handleItemClick('appetizer')}/>

      {/* Realistic Dessert */}
      <Entity primitive="a-cylinder" color="#D2691E" radius="0.2" height="0.2" position="0.5 1.1 -1.5" onClick={() => handleItemClick('dessert')}/>

      {/* Realistic Sauce */}
      <Entity primitive="a-cylinder" color="#A52A2A" radius="0.1" height="0.3" position="-0.5 1.15 -2.5" onClick={() => handleItemClick('sauce')}/>

      {/* Realistic Drink */}
      <Entity primitive="a-cylinder" color="##ff2d00" radius="0.1" height="0.4" position="0.5 1.2 -2.5" onClick={() => handleItemClick('drink')}/>

      {/* Menu Overlay */}
      {menuState.open && (
        <Entity primitive="a-plane" color="white" height="2" width="3" position="0 2 -4">
          <Entity primitive="a-text" value={`Menu: ${menuState.item}`} color="black" position="-0.5 0.5 0.1"/>
          {/* Additional content based on clicked item */}
        </Entity>
      )}

      {/* Lights and Camera */}
      <Entity primitive="a-light" type="ambient" color="#445" intensity="8"/>
      <Entity primitive="a-camera" position="0 2 5"/>
    </Scene>
  );
}

export default KitchenScene;
