import { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from "../admin-navbar.component";

const Food = props => (
    <tr>
        <td> {props.name} </td>
        <td> {props.nutrition}</td>
        <td>{props.level}</td>
        <td><Link to={"/admin/allergies/edit/" + props._id}><button className="btn btn-primary">Edit</button></Link></td>
    </tr>
)

export default class AllergyList extends Component {
    constructor(props) {
        super(props);
        this.allergyList = this.allergyList.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeLevel = this.onChangeLevel.bind(this);
        this.onChangeNutrition = this.onChangeNutrition.bind(this);

        this.state = {
            allergens: [],
            name: "",
            level: 1,
            nutrition: ""
        }
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeLevel(e) {
        this.setState({
            level: e.target.value
        });
    }
    
    onChangeNutrition(e) {
        this.setState({
            nutrition: e.target.value
        });
    }

    componentDidMount() {
        axios.get("https://adits-notesapp.herokuapp.com/allergies")
        .then(response => {
            this.setState({ allergens: response.data });
        })
        .catch((error) => {
            console.log(error);
        });
    }

    allergyList() {
        return this.state.allergens.map(allergen => {
            return <Food name={allergen.name} level={allergen.level} nutrition={allergen.nutrition} _id={allergen._id}/>
        });
    }



    onSubmit(e) {
        e.preventDefault();
        const food = {
            name: this.state.name,
            level: this.state.level,
            nutrition: this.state.nutrition
        }
        axios.post("https://adits-notesapp.herokuapp.com/allergy/add", food)
            .then(res => setTimeout(() => { 
                window.location = "/admin/allergies/allergieslist";}
                , 250));
    }

    render() {
        return (
            <div className="container-fluid">
                <Navbar />
                <title>Adit's Allergies</title>
                <h1>Allergy Admin</h1>

                <table className="table table-sm table-responsive-sm table-hover ">
                    <thead className="thead-light">
                        <tr>
                        <th> Allergen </th>
        <th > Food Group </th>
        <th > Severity </th>
        <th> Action </th>
                            {/* <th > Actions </th> */}
                        </tr>
                    </thead>
                    <tbody>
                    {this.allergyList()}
                   
                        <tr> 
                            <td>
                                <input
                                    type="text"
                                    value={this.state.name}
                                    onChange={this.onChangeName}
                                />
                                </td> <td>
                                <input
                                    type="text"
                                    value={this.state.nutrition}
                                    onChange={this.onChangeNutrition}
                                />        </td><td>                        <input
                                    type="text"
                                    value={this.state.level}
                                    onChange={this.onChangeLevel}
                                />
                            </td>
                            
                        
                            <td>
                                <input
                                    type="submit"
                                    value="Add"
                                    className="btn btn-success"
                                    onClick={this.onSubmit}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}



