//import {BrowserRouter as Router, Route} from "react-router-dom";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"
import AnimatedCursor from "react-animated-cursor"
import Home from "./components/all/home.component";

//Professional
import Professional from "./components/all/all_professional/professional.component";
import Allergy from "./components/all/allergies.component";

//Personal
import Blog from "./components/all/all_blog/blog.component";
import ViewRecipie from "./components/all/all_recipie/viewRecipie.component";
import Recipies from "./components/all/all_recipie/recipies.component";
import Recipies3 from "./components/all/all_recipie/recipiebud.component";

//Admin
import useToken from './useToken';
import AllergyList from "./components/admin/admin_fronteditors/allergies-list.component";
import EditAllergy from "./components/admin/admin_fronteditors/edit-allergy.component";
import NotesList from "./components/admin/admin_todo/notes-list.component";
import EditNote from "./components/admin/admin_todo/edit-note.component";
import Access from "./components/all/access.component";
import Print from "./components/admin/admin_todo/print.component";
import AdminRecipie from "./components/admin/admin_fronteditors/admin-recipie.component";
import EditRecipie from "./components/admin/admin_fronteditors/edit-recipie.component";
import BlogPost from "./components/admin/admin_fronteditors/blogposts.component"

//CRM
import CRM from "./components/admin/admin_crm/crm.component"
import CRML from "./components/admin/admin_crm/crm-loc.component"
import CRMR from "./components/admin/admin_crm/reach-user.component"

//Test
// import Scan from "./components/testing/scan.component"

function App() {  
  const token = useToken();
  return (

    <Router>
          <AnimatedCursor
      innerSize={8}
      outerSize={8}
      color='255,214,0'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',      ]}
    />

      <Routes>  
      <Route path="/" exact element={<Home/>}/>
      <Route path="/blog" exact element={<Blog/>}/>
      <Route path="/allergies" exact element={<Allergy/>}/>
      <Route path="/recipies" exact element={<Recipies/>}/>
      <Route path="/recipies3" exact element={<Recipies3/>}/>
      <Route path="/recipies/view/:id/*" exact element={<ViewRecipie/>}/>
      <Route path="/professional" exact element={<Professional/>}/>
      { token ? ( <>
      <Route path="/access" exact element={<Access/>}/>
      <Route path="/admin/notes/edit/:id" exact element={<EditNote/>}/>
      <Route path="/admin/notes" exact element={<NotesList/>}/>
      <Route path="/admin/notes/print" exact element={<Print/>}/>
      <Route path="/admin/allergies/allergieslist" exact element={<AllergyList/>}/>
      <Route path="/admin/allergies/edit/:id" exact element={<EditAllergy/>}/>
      <Route path="/admin/recipies/" exact element={<AdminRecipie/>}/>
      <Route path="/admin/recipies/edit/:id" exact element={<EditRecipie/>}/>  
      <Route path="/admin/blogs/" exact element={<BlogPost/>}/>
      <Route path="/admin/crm" exact element={<CRM/>}/>
      <Route path="/admin/loci" exact element={<CRML/>}/>
      <Route path="/admin/texter" exact element={<CRMR/>}/>
      </>
      ):
      <Route path="/access" exact element={<Access/>}/>
      }
    </Routes>
  
    </Router>
    
  );
}

export default App;
