import { Component } from 'react';
import axios from 'axios';
//import DatePicker from 'react-horizontal-datepicker';
// import ReactNotifications from 'react-browser-notifications';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import Navbar from "../admin-navbar.component";
import "./notes.css";
import EditNote from "./edit-note.component";
import globe from "./globe.svg";
import { Link } from 'react-router-dom';

// import icon from "../favicon.ico";

const Note = props => (
    <tr className="fullnote" >
        <td><span className="date">{(new Date(props.note.date)).toLocaleString().substring(0, (new Date(props.note.date)).toLocaleString().indexOf('/202'))}:</span> <span className="note"><strong>{props.note.topic}</strong></span></td>
        <td className="crmPad"> <input className="locInput"
            type="text"
            placeholder='Tag?'
            style={{width: 60}}
            defaultValue={props.note.tag}
            onBlur={(event) => props.onChangeTag(props.note._id,event.target.value)}
          /></td>
        <td><button className="bt2" onClick={() => { props.complete(props.note._id) }}>Complete</button> | <button className="bt1" onClick={() => props.edit(props.note._id)}>Edit</button></td>
    </tr>
)

const Person = props => (
    <td className='crmwidgetspace' style={{color: props.namer(props.identity._id)}}><span className="crmWidgetDate">{(new Date(props.identity.nextDay)).toLocaleString('en-US', {timeZone: 'UTC'}).substring(0, (new Date(props.identity.nextDay)).toLocaleString().indexOf('/202'))}</span> <span className="crmWidgetName" style={{color: props.namer(props.identity._id)}}><strong>{props.identity.firstname} </strong></span></td>
)

// const Group = props => (
//     <div >
//     <h2 className='areaTitle' style={{"textAlign":'left'}}> {props.area} </h2>
//     <table className="table" >
//         <tbody>
//         {props.customers}
//         </tbody>
//         </table>
//         </div>
// )


export default class NotesList extends Component {
    constructor(props) {
        super(props);
        this.complete = this.complete.bind(this);
        this.onChangeTopic = this.onChangeTopic.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        
        this.onChangePlan = this.onChangePlan.bind(this);
        this.onChangeofPlan = this.onChangeofPlan.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onSubmitEnter = this.onSubmitEnter.bind(this);
        this.changeView = this.changeView.bind(this);
        this.pullNotes = this.pullNotes.bind(this);
        this.onLiveChange = this.onLiveChange.bind(this);
        this.printer = this.printer.bind(this);
        this.edit = this.edit.bind(this);
        this.close  = this.close.bind(this);
        this.onChangeTag = this.onChangeTag.bind(this)
        this.refresh = this.refresh.bind(this)
        this.undo = this.undo.bind(this)
        this.tempSwitch = this.tempSwitch.bind(this)
        
        this.namer = this.namer.bind(this);
        this.crmWidget = this.crmWidget.bind(this);
        
        this.reachWidget = this.reachWidget.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            topic: '',
            date: new Date(),
            notes: JSON.parse("[]"),
            crm: JSON.parse("[]"),
            reach: '',
            view: '',
            plan: '',
            planid: "",
            last: new Date(),
            vd: <></>,
            lastComplete: ""
        }

    }

    close(){     
        this.setState({
            vd: Component,
        });
        setTimeout(()=>{
            this.refresh()
        },500); 
    }

    edit(id){     
        this.setState({
            dialog: !this.state.dialog,
            vd: <EditNote kid={id} close={this.close} edit={this.edit}/>
        })
    }

    namer(id) {
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === id))[0];
        if (!customer.bdaycard) {
            return "#032b43"
        }
        else if (customer.upgrade){
            return "gold"
        }
        else 
            return "white"
    }

    printer(){
        console.log("Yo Imma print now")
    }
    
    onChangeTag(id,nTag) {
                    
        var note  =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj._id === id))[0];
            var customerString = JSON.stringify(note)
            note.tag = nTag
            var notes = localStorage.getItem("notes")
            notes = notes.replace(customerString,JSON.stringify(note))
            localStorage.setItem('notes',notes);
            this.setState({
                notes: JSON.parse(notes).sort(function(a, b) {
                    return (a["date"] > b["date"]) ? 1 : ((a["date"] < b["date"]) ? -1 : 0);
                  })
            }

            )
            if(navigator.onLine){
        
                axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + id, note)
            .then(res => setTimeout(() => { 
                // console.log(res.data); 
                // this.pullNotes(this.state.view);
                 
            }, 250))
            .catch((error) => {console.log(error);});}

            setTimeout(()=>{
                this.refresh()
            },500);   
    }

    onChangeTopic(e) {
        this.setState({
            topic: e.target.value
        });
    }

    onChangeDate(date) {
        this.setState({
            date: date
        });
    }

    onLiveChange(){
        var status = document.getElementsByName("Live").item(0);
         if (navigator.onLine)
         status.className = "online"
         else{
         status.className = "offline"
     }
     }

    componentDidMount(){
        // console.log(typeof(localStorage.getItem('notes')))
        if(localStorage.getItem('notes')==null){
            console.log("Second cause")
        }
        else if(localStorage.getItem('notes')!==""){
            // console.log("Cause "+localStorage.getItem('notes'))
            // this.setState({notes: JSON.parse(localStorage.getItem("notes"))})
            var plan = JSON.parse(localStorage.getItem("notes")).filter(obj=> obj.display)[0];
            this.setState({plan: plan.topic, planid: plan._id });
        }
        
        // console.log("Bakwass is" + sessionStorage.getItem('view'))
        if(sessionStorage.getItem('view')!=="" && sessionStorage.getItem('view')!==null){
            this.setState({view: sessionStorage.getItem('view')})
        }
        else {
            this.setState({view: "Day"})
            sessionStorage.setItem('view', "Day")
        }
        const viewE = Array.from(document.getElementsByName(sessionStorage.getItem('view')))[0];
        viewE.className = "view"
        this.pullNotes()
        this.reachWidget()
    }

    complete(id) {
        // if(localStorage.getItem('live')==="1"){
            if(navigator.onLine){
        
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/complete/" + id, { complete: true })
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                // this.pullNotes(this.state.view);
                localStorage.setItem('live',"1")
                this.onLiveChange()
            }, 250))
            .catch((error) => {
                console.log(error);
                localStorage.setItem('live',"0")
                this.onLiveChange()
           //     window.location = "/access";
            });}
        var note =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj._id === id))[0];
        var noteString = JSON.stringify(note)
        var notes = localStorage.getItem("notes")
        // console.log(JSON.parse(localStorage.getItem('notes')));
        // console.log(JSON.parse(notes))
        if(note.nextTil>0){
            note.date = new Date((new Date(note.date)).setDate((new Date(note.date)).getDate() + note.nextTil))
            notes = notes.replace(noteString,JSON.stringify(note)) 
        }
        else {
            notes = notes.replace(","+noteString,"")
        }
        localStorage.setItem('notes',notes);
        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(note)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        this.setState({
            lastComplete: id
        });

        setTimeout(()=>{
            this.refresh()
        },500);      
    }

    onChangePlan(e){
        this.setState({
            plan: e.target.value
        });    
    }

    onChangeofPlan(e){
        e.preventDefault();
        const note = {
            topic: this.state.plan,
            date: this.state.date,
            complete: false,
            display: true,
            owner: localStorage.getItem('token')
        };
        // localStorage.getItem('notes').match()
        // if(localStorage.getItem('live')==="1"){
            if(navigator.onLine){
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + this.state.planid, note)
            .then(res => setTimeout(() => { 
                // console.log(res.data); 
                localStorage.setItem('live',"1")
                this.onLiveChange()
             }, 250))
             .catch((error) => {
                console.log(error);
                localStorage.setItem('live',"0")
                this.onLiveChange()
           //     window.location = "/access";
            });
        }
        var noted =  JSON.parse(localStorage.getItem("notes")).filter(obj=> (obj.display))[0];
        var noteString = JSON.stringify(noted)
        var notes = localStorage.getItem("notes")
        // console.log(JSON.parse(localStorage.getItem('notes')));
        // console.log(JSON.parse(notes))
        noted.topic = this.state.plan;
        notes = notes.replace(noteString,JSON.stringify(noted)) 
        // if(localStorage.getItem('live')==="0"){
        //     var off = JSON.parse(localStorage.getItem('off')).
        //     off.push(noted)
        //     localStorage.setItem('off',JSON.stringify(off))
        //     console.log(off)
        // }
        localStorage.setItem('notes',notes);
        setTimeout(()=>{
            this.refresh()
        },500);     
    }

    onSubmit(e) {
        // if(localStorage.getItem('live')==="1"){
        if(navigator.onLine){
        e.preventDefault();
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: localStorage.getItem('token')
        }
    

        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
                localStorage.setItem('live',"1")
                this.onLiveChange()
            }
                , 250))
                .catch((error) => {
                    console.log(error);
                    localStorage.setItem('live',"0")
                    this.onLiveChange()
               //     window.location = "/access";
                });
            this.setState({
                    topic: '',
                    date: new Date()
                });
                setTimeout(()=>{
                    this.refresh()
                },500);  }
    }

    onSubmitEnter(event) {
    // if(localStorage.getItem('live')==="1"){
        if(navigator.onLine){
    if (event.key === "Enter") {
        const note = {
            topic: this.state.topic,
            date: this.state.date,
            archive: this.state.view==="Archive",
            owner: localStorage.getItem('token')
        }
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/add", note)
            .then(res => setTimeout(() => { 
                console.log(res.data); 
                this.pullNotes(this.state.view);
                localStorage.setItem('live',"1")
                this.onLiveChange()
            }
                , 250))
                .catch((error) => {
                    console.log(error);
                    localStorage.setItem('live',"0")
                    this.onLiveChange()
               //     window.location = "/access";
                });
            this.setState({
                    topic: '',
                    date: new Date()
                });
                setTimeout(()=>{
                    this.refresh()
                },500);  
            }
    }
    
}

    notesList() {
        if (localStorage.getItem("notes")===''){
            return
        }
        return this.state.notes.map(currentnote => {
            return <Note note={currentnote} edit={this.edit} complete={this.complete} key={currentnote._id} onChangeTag={this.onChangeTag} />
        });
    }

    reachWidget() {
        if (localStorage.getItem("crm")==='' || localStorage.getItem("crm")==null){
            return
        }
        
        var text = "Reach out to: " 
        var mini = JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
            var valA = (new Date(a.lastReach)).setDate((new Date(a.lastReach)).getDate()+a.next)
            var valB = (new Date(b.lastReach)).setDate((new Date(b.lastReach)).getDate()+b.next)
            return (valA > valB) ? 1 : (valA < valB ? -1 : 0);}).slice(0, 6)
        for (let index = 0; index < mini.length; index++) {
            text += mini[index].firstname + " " + mini[index].lastname
            if (index < mini.length-1){
                text += ", "
            }
        }
        // console.log(mini[0])    
        // console.log(text)
        this.setState({ 
            
            reach: text
        })
            // .map(person => {return <RPerson identity={person} birthdaykey={person._id} namer={this.namer}/> });
    }

    crmWidget() {
        if (localStorage.getItem("crm")==='' || localStorage.getItem("crm")==null){
            return
        }

        return JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
            return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
          }).slice(0, 10).map((person,index) => {
            return <Person identity={person} key={"pers"+index} birthdaykey={person._id} namer={this.namer}/>
        });

        //       const firstDate = new Date();
        //     firstDate.setUTCHours(0,0,0,0);
        //     firstDate.setDate(firstDate.getDate()+4);
        //     // const lastDate = new Date(firstDate);
        //     // lastDate.setDate(firstDate.getDate()+1);
        //     const date = firstDate;
        // const details = "";
        // const display = false;
        // const nextTil = 0;
        // const complete = false;
        // const archive = false;
        // const owner = req.body.owner;
        // const tag = " ";
        // // console.log(firstDate)
        // // // console.log(lastDate)
        // //     // this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && new Date(obj.date)<=lastDate) });
        // // console.log(req.body.owner)    
        // Customer.find( {nextDay: firstDate, hide: false, owner: req.body.owner}).then(bbg => {  
        //         console.log(bbg)
        //         console.log(bbg===undefined || bbg.toString()==="")
        //         if (!(bbg===undefined || bbg.toString()==="")){ 
        //             console.log("Enters?")
        //         bbg.forEach(pers => { 
        //             console.log("Found "+pers.firstname)
        //     const topic = "HBD to "+pers.firstname + " " + pers.lastname + " from " + pers.tag;
           
        
    }

    viewHelper() {
        var view = sessionStorage.getItem('view');
        if (view==="Tom") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+2);
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
        }
        else if (view==="Day") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+1);
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && new Date(obj.date)<=lastDate) });
        }
        else if (view==="Week") {
            const firstDate = new Date();
            firstDate.setSeconds(0);
            firstDate.setMinutes(0);
            firstDate.setHours(0);
            const lastDate = new Date(firstDate);
            lastDate.setDate(firstDate.getDate()+7);
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && new Date(obj.date) >= firstDate && new Date(obj.date)<=lastDate) });
        }
        else if (view==="Recuring") {
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.archive && !obj.display) && obj.nextTil >0) });
        }
        else if (view==="Archive") {
            // this.setState({ notes: this.state.notes.filter(obj=> (new Date(obj.date)>=firstDate && new Date(obj.date)<=lastDate)) });
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.display) && obj.archive) });
        }
        else {
            this.setState({ notes: JSON.parse(localStorage.getItem("notes")).filter(obj=> (!obj.display) && !obj.archive) }); 
        }

    } 

    changeView(e) {
        var view = "";
        const viewE = Array.from(document.getElementsByName(sessionStorage.getItem('view')))[0];
        viewE.className = "circle"
        if (e){
        this.setState({
            view: e.target.name,
            lastevent: e
        });
       
        e.target.className = "view";
        view = e.target.name;
        sessionStorage.setItem('view', view);
        }
        else {
            view = sessionStorage.getItem('view');
        }
        console.log("View set "+view)
        this.viewHelper()   
    }

    // groupsList() {
    //     if (localStorage.getItem("crm")===''){
    //         return
    //     }
        

    //     // console.log("Below is Notes:")
    //     // console.log(this.state.notes)
    //     var customers = this.state.notes
    //     var tags = [];
    //     customers.forEach(element => {
    //         if (tags.indexOf(element.tag)===-1)
    //             tags.push(element.tag);
    //     });
    //     return tags.sort().map(tag => {
    //         return <Group area={tag} key={tag} customers={this.state.notes.filter(obj=> obj.tag === tag).sort(function(a, b) {
    //             return (a["date"] > b["date"]) ? 1 : ((a["date"] < b["date"]) ? -1 : 0);
    //           }).map(currentnote => {
    //         return <Note note={currentnote} complete={this.complete} key={currentnote._id} onChangeTag={this.onChangeTag} />
    //     })}/>
    //     });

    // }

    pullNotes(views){
        console.log("Being called");
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/", {owner: localStorage.getItem('token')})
        .then(response => {
            if (JSON.stringify(response.data)!==(localStorage.getItem("notes"))){  // forgot what this does
                console.log("Update")
                // this.setState({ notes: response.data });
                localStorage.setItem('notes', JSON.stringify(response.data));
                var plan = response.data.filter(obj=> obj.display)[0];
                this.setState({plan: plan.topic, planid: plan._id });
                // console.log(localStorage.getItem("notes"));
            }
            else {
                console.log("No Dif");
                this.setState({ notes: JSON.parse(localStorage.getItem("notes"))});
                plan = JSON.parse(localStorage.getItem("notes")).filter(obj=> obj.display)[0];
                this.setState({plan: plan.topic, planid: plan._id });
                // console.log(this.state.notes)
            } 
            this.viewHelper()
            localStorage.setItem('live',"1")
            this.onLiveChange()
        })
        .catch((error) => {
            console.log(error);
            localStorage.setItem('live',"0")
            this.onLiveChange()
       //     window.location = "/access";
        });
        axios.post("https://adits-notesapp.herokuapp.com/crm/", {owner: localStorage.getItem('token')})
        .then(response => {
            if (JSON.stringify(response.data)!==(localStorage.getItem("crm"))){  // forgot what this does
                console.log("Update")
                localStorage.setItem('crm', JSON.stringify(response.data));
                this.setState({ crm: response.data });
                // console.log(localStorage.getItem("notes"));
                return JSON.parse(localStorage.getItem("crm")).sort(function(a, b) {
                    return (a["nextDay"] > b["nextDay"]) ? 1 : ((a["nextDay"] < b["nextDay"]) ? -1 : 0);
                  }).slice(0, 10).map(person => {
                    return <Person identity={person} birthdaykey={person._id} namer={this.namer}/>
                });
            }
            else {
                console.log("No Dif");
                this.setState({ crm: JSON.parse(localStorage.getItem("crm"))});
            } 
        })
        .catch((error) => {
            console.log(error);
            localStorage.setItem('live',"0")
       //     window.location = "/access";
        })

        }

    refresh(){
        if(navigator.onLine){
        this.pullNotes()
        
        setTimeout(()=>{
            this.reachWidget()
            this.changeView()
        },500); 
    }
    }

    undo(){
        if(navigator.onLine){
        console.log(this.state.lastComplete)
        var note = "";
        axios.get("https://adits-notesapp.herokuapp.com/admin/notes/" + this.state.lastComplete)
        .then((response) => {
            note = response.data
            note.complete = false;
        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/update/" + this.state.lastComplete, note)
        
        })
        setTimeout(()=>{
            this.refresh()
        },500); 
        
    }
    }

    tempSwitch(){
      var owner = localStorage.getItem("token")
    //   var status = document.getElementsByName("Switch").item(0);
      if(owner === "5ff152b6cee104066006d600"){
        localStorage.setItem("token","659ed617251167d42a73fd37")   
 localStorage.setItem("profile","Work")   
       }
       else if (owner==="659ed617251167d42a73fd37"){
        localStorage.setItem("token","5ff152b6cee104066006d600")   
        localStorage.setItem("profile","Pers")
       }
       this.refresh()

    }

    render() {
        return (
            <div className="container-fluid">
                <Navbar />
                <title>To Do List</title>
                
                <textarea className= "plan" rows="4"
                                    type="text"
                                    value={this.state.plan}
                                    onChange={this.onChangePlan}
                                />
  <a href="/admin/notes/print"><button className="printbtn">Print</button></a>
       
        <input className="plansubmit"
                                    type="submit"
                                    value="Update"
                                    
                                    onClick={this.onChangeofPlan}
                                />
        
        {this.state.vd}
        <table className="table table-responsive" style={{display: "block", width:'auto'}}> <tbody><tr><Link to={"/admin/loci"}><img src={globe} alt="globe" style={{'width':'4vw','backgroundColor':'#6E5794','marginTop':'3%'}}/></Link><Link to="/admin/crm" style={{'color': 'transparent'}}>{this.crmWidget()}</Link> </tr></tbody></table>
        <Link to="/admin/texter" style={{'color': 'transparent'}}><div style={{'color': 'white','marginBottom':"1.5%", "paddingTop":".5%", "font":"Raleway", "backgroundColor":"#347FC4","width":"fit-content"}}><strong>{this.state.reach}</strong></div></Link>
        
              
                <table className="table table-responsive" style={{display: "block",width:'auto'}}>
                    <thead>
                        <tr>
                            <td>
                                <button type="button" name="All" className="circle" onClick={this.changeView}>All</button></td>
                                <td><button type="button" name="Day" className="circle" onClick={this.changeView}>Today</button></td>
                                <td><button type="button" name="Tom" className="circle" onClick={this.changeView}>Tomorrow</button></td>
                                <td><button type="button" name="Week" className="circle" onClick={this.changeView}>Week</button></td>
                                <td><button type="button" name="Recuring" className="circle" onClick={this.changeView}>Recuring</button></td>
                                <td><button type="button" name="Archive" className="circle" onClick={this.changeView}>Archive</button></td>
                                <td><button type="button" name="Live" className="offline" onClick={this.refresh}>Live</button></td>
                                <td><button type="button" name="Undo" className="offline" onClick={this.undo}>Un</button></td>      
                                <td><button type="button" name="Switch" className="online" onClick={this.tempSwitch}>{localStorage.getItem("profile")}</button></td>                     
                        </tr>
                    </thead>
                </table>
                <table className=''></table>
                <table className="table table-sm table-responsive-sm table-hover" >
                    <tbody>
                    {/* {this.groupsList()} */}
                    {this.notesList()}
                    
                        <tr> 
                            <td>
                                <DatePicker className="date"//npm install react-datepicker
                                    selected={this.state.date}
                                    onChange={this.onChangeDate}
                                />
                                <input className="newnote"
                                    type="text"
                                    value={this.state.topic}
                                    onChange={this.onChangeTopic}
                                    onKeyPress={this.onSubmitEnter}
                                />
                            </td>
                            
                        
                            <td>
                                <input
                                    type="submit"
                                    value="Create"
                                    className="bt2"
                                    onClick={this.onSubmit}
                                />
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
        )
    }
}