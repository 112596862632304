import { Component } from 'react';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import "./crm.css";

//use for adding and editing user, only difference is functionality of confirmed

export default class AddUser extends Component {
  constructor(props) {
    super(props);
    this.onInfoChange = this.onInfoChange.bind(this);
    this.onChangeBDate = this.onChangeBDate.bind(this);
    this.changeView = this.changeView.bind(this);
    this.onChangeNDate = this.onChangeNDate.bind(this);
    this.onChangeRDate = this.onChangeRDate.bind(this);
    this.onChangeMDate = this.onChangeMDate.bind(this);
    this.hide = this.hide.bind(this);
    this.send = this.send.bind(this);
    this.currentBday = this.currentBday.bind(this)
    this.onOptionChangeHandler = this.onOptionChangeHandler.bind(this)
    // this.showNotifications = this.showNotifications.bind(this);
    // this.handleClick = this.handleClick.bind(this);
    if(props.kid){
      var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === props.kid))[0];
      this.state = {
        first: customer.firstname,
        last: customer.lastname,
        aff: customer.type,
        bday: new Date(new Date(customer.birthday).setMinutes(new Date(customer.birthday).getMinutes()+60+new Date().getTimezoneOffset())),
        next: new Date(new Date(customer.nextDay).setMinutes(new Date(customer.nextDay).getMinutes()+60+new Date().getTimezoneOffset())),
        status: customer.ful,
        card: customer.bdaycard,
        up: customer.upgrade,
        season: customer.seasonal,
        mithai: customer.smithai,
        giftL: customer.bgift,
        gift: customer.gift,
        dialog: false,
        mDate: new Date(customer.lastMet),
        rDate: new Date(customer.lastReach),
        address: customer.address,
        comment: customer.comment,
        c_code: customer.c_code,
        phone: customer.phone,
        hide: false
      
    }
        var arr = Array.from(document.getElementsByClassName("circleInput"))
        console.log(arr)
        arr.forEach(elm => {
          if (customer.bdaycard & elm.name === "Card"){
            console.log("reaches?")
            elm.className = "circleInputLive";
          }
          else if (customer.upgrade & elm.name === "Up"){
            elm.className = "circleInputLive";
          }
          else if (customer.card & elm.name === "Diwali"){
            elm.className = "circleInputLive";
          }
          else if (customer.card & elm.name === "Mithai"){
            elm.className = "circleInputLive";
          }
          else if (customer.card & elm.name === "Gift"){
            elm.className = "circleInputLive";
          }
          return elm;
          }
          );
  }
    else {
    this.state = {
      first: '',
      last: '',
      aff: '',
      bday: new Date(),
      status: '',
      card: false,
      up: false,
      season: false,
      mithai: false,
      giftL: "",
      gift: false,
      dialog: false,
      mDate: new Date(),
      rDate: new Date(),
      address: "",
      comment: "",
      c_code: 0,
      phone: 0,
    }
    }
  }


  onInfoChange(e) {
    e.preventDefault();
    
    const classNameToStateMap = {
      "firstNameInput": "first",
      "lastNameInput": "last",
      "affInput": "aff",
      "giftInput": "giftL",
      "addressInput": "address",
      "commentInput": "comment",
      "ccodeInput": "c_code",
      "phInput": "phone"
    };
  
    const className = e.target.className;
    const stateProperty = classNameToStateMap[className];
  
    if (stateProperty) {
      this.setState({
        [stateProperty]: e.target.value
      });
    }
  }
  

  onChangeBDate(date) {
    this.setState({
      bday: date
    });
  }

  onChangeNDate(date) {
    this.setState({
      next: date
    });
  }

  onChangeRDate(date) {
    this.setState({
      rDate: date
    });
  }

  onChangeMDate(date) {
    this.setState({
      mDate: date
    });
  }

  changeView(e) {
    var stat = false;
    if (e && e.target.className==="circleInput"){
      // e.target.className = "circleInputLive";
      stat = true;
    }
    else if (e) {
      // e.target.className = "circleInput";
      stat = false;
    }

    if (e.target.name === "Card"){
      this.setState({
        card: stat
      })
    }
    else if (e.target.name === "Up"){
      this.setState({
        up: stat
      })
    }
    else if (e.target.name === "Diwali"){
      this.setState({
        season: stat
      })
    }
    else if (e.target.name === "Mithai"){
      this.setState({
        mithai: stat
      })
    }
    else if (e.target.name === "Gift"){
      this.setState({
        gift: stat
      })
    }
  }

  onOptionChangeHandler(e){
    this.setState({
      status: e.target.value
    })
  }

  currentBday (date) {
    const next = new Date(date);
    const temp = new Date();
    next.setFullYear(temp.getFullYear())
    if(next < temp){
      next.setFullYear(temp.getFullYear()+1)
    }
    return next;
  }
  
  send (e){
    if(e){
    e.preventDefault()}
    var customer = "";
    if (this.props.kid){
      customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === this.props.kid))[0];
      var customerString = JSON.stringify(customer)
      var crm = localStorage.getItem("crm")
      if(customer.hide){
        crm = crm.replace(","+customerString,"")
      } 
      else {
        customer.firstname = this.state.first;
        customer.lastname = this.state.last;
        customer.type = this.state.aff;
        customer.birthday = this.state.bday;
        customer.ful = this.state.status;
        customer.bdaycard = this.state.card;
        customer.upgrade = this.state.up;
        customer.seasonal = this.state.season;
        customer.smithai = this.state.mithai;
        customer.bgift = this.state.giftL;
        customer.gift = this.state.gift;
        customer.lastMet = this.state.mDate;
        customer.lastReach = this.state.rDate;
        customer.address = this.state.address;
        customer.comment = this.state.comment;
        customer.nextDay = this.state.next;
        customer.c_code = this.state.c_code
        customer.phone = this.state.phone
        crm = crm.replace(customerString,JSON.stringify(customer))
      }
      localStorage.setItem('crm',crm);
    
    }

    if(navigator.onLine){
      if(!this.props.kid){
      customer = {
      owner: localStorage.getItem('token'),
      firstname: this.state.first,
      lastname: this.state.last,
      type: this.state.aff,
      birthday: this.state.bday,
      ful: this.state.status,
      bdaycard: this.state.card,
      upgrade: this.state.up,
      seasonal: this.state.season,
      smithai: this.state.mithai,
      bgift: this.state.giftL,
      gift: this.state.gift,
      lastMet: this.state.mDate,
      lastReach: this.state.rDate,
      address: this.state.address,
      comment: this.state.comment,
      c_code: this.state.c_code,
      phone: this.state.phone,
      // nextDay: this.state.next,
      hide: false,
      nextDay: this.currentBday(this.state.bday),
  }
  axios.post("https://adits-notesapp.herokuapp.com/crm/add", customer)
      .then(res => setTimeout(() => { 
        window.location = "/admin/crm/";
      }
          , 250))
          .catch((error) => {
              console.log(error);
          });
        }
    else {
      axios.post("https://adits-notesapp.herokuapp.com/crm/update/" + this.props.kid, customer)
      .then(res => setTimeout(() => { 
          console.log(res.data); 
          // this.pullNotes(this.state.view);
           
      }, 250))
      .catch((error) => {
          console.log(error);
         
      });
    }
  }
  
  this.props.close()
  }

  hide (e){
    e.preventDefault()
    if(this.props.kid){
      console.log("tries?")
        this.setState({
          hide: true
        })
        var customer  =  JSON.parse(localStorage.getItem("crm")).filter(obj=> (obj._id === this.props.kid))[0];
        var customerString = JSON.stringify(customer)
        customer.hide = true;
        var crm = localStorage.getItem("crm")
        crm = crm.replace(customerString,JSON.stringify(customer))
        localStorage.setItem('crm',crm);
      }
      this.send()
      this.props.close()
  
  }

  render() {
    return (
      <dialog id="additLog" className='modCRMlog' open>
          <input className="firstNameInput"
            type="text"
            placeholder='First'
            value={this.state.first}
            onChange={this.onInfoChange}
          />
          <input className="lastNameInput"
            type="text"
            placeholder='Last'
            value={this.state.last}
            onChange={this.onInfoChange}
          />
          <button className="bt2" onClick={() => this.props.close()} style={{color: "#FFD600", borderColor:"#FFD600", position: "absolute", right:"2%"}} >X</button>
          <br />
          <input className="affInput"
            type="text"
            placeholder='Affiliation'
            value={this.state.aff}
            onChange={this.onInfoChange}
          />
          <div>
            <DatePicker //npm install react-datepicker
              className="bdayInput"
              selected={this.state.bday}
              onChange={this.onChangeBDate}
            />
            <br/>
            <DatePicker //npm install react-datepicker
              className="nextInput"
              selected={this.state.next}
              onChange={this.onChangeNDate}
            />
            <br/>
            <label><span className='ent'>Entitlement: </span>
              <select className='statusInput' onChange={this.onOptionChangeHandler}>
                <option value="default">{this.state.status !== "" ? this.state.status : "Start"}</option>
                <option style={{color: "green"}}>Complete</option>
                <option style={{color: "green"}}>RTS</option>
                <option style={{color: "yellow"}}>In Progress</option>
                <option style={{color: "yellow"}}>Find Gift</option>
                <option style={{color: "yellow"}}>Buy Gift</option>
                <option style={{color: "yellow"}}>Update Address</option>
                <option style={{color: "red"}}>N/A</option>
                <option style={{color: "red"}}>Start</option>
              </select>
            </label>
          </div>
          <td> <button type="button" name="Card" className={this.state.card ? "circleInputLive" : "circleInput"} onClick={this.changeView}>Card</button></td>
          <td><button type="button" name="Up" className={this.state.up ? "circleInputLive" : "circleInput"} onClick={this.changeView}>Up</button></td>
          <td><button type="button" name="Mithai" className={this.state.mithai ? "circleInputLive" : "circleInput"} onClick={this.changeView}>Texter</button></td>
          <td><button type="button" name="Gift" className={this.state.gift ? "circleInputLive" : "circleInput"} onClick={this.changeView}>Gift</button></td>
          <textarea className="giftInput" rows="1"
            type="text"
            placeholder="Gift Link"
            value={this.state.giftL}
            onChange={this.onInfoChange}
          />
          <table style={{color: "white", fontFamily:"Raleway", textAlign:"center"}}>
            <thead>
              <th>Last Reach</th>
              <th>Last Met</th>
            </thead>
            <tbody>
              <td>
              <DatePicker //npm install react-datepicker
            className="lastRInput"
          selected={this.state.rDate}
          onChange={this.onChangeRDate}
          />
              </td>
              <td>
              <DatePicker //npm install react-datepicker
            className="lastMInput"
          selected={this.state.mDate}
          onChange={this.onChangeMDate}
          />
              </td>
              <tr>
              <td>     <input className="ccodeInput"
            type="text"
            style={{width: "30px"}}
            placeholder='Country Code'
            value={this.state.c_code}
            onChange={this.onInfoChange}
          /></td><td>
           <input className="phInput"
            type="text"
            placeholder='Number'
            value={this.state.phone}
            onChange={this.onInfoChange}
          /></td></tr>
            </tbody>
          </table>

     
          <textarea className="addressInput" rows="2"
            type="text"
            placeholder="Address"
            value={this.state.address}
           onChange={this.onInfoChange}
          />
          <br/>
          <textarea className="commentInput" rows="2"
            type="text"
            placeholder="Comments"
            value={this.state.comment}
           onChange={this.onInfoChange}
          />
          <div>
            <button className="bt1" id="confirmChange" value="default" onClick={this.send} style={{marginRight: "3%"}} >✓</button>
            <button className="bt3" id="hidePerson" value="default" onClick={this.hide} >Hide</button>
            
          </div>
      </dialog>
    )
  }
}