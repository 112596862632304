import { Component } from 'react';
import axios from 'axios';
import "./edit-recipie.css";
// import icon from "../favicon.ico";
// import ReactNotifications from 'react-browser-notifications';

export default class EditRecipie extends Component {
    constructor(props) {
        super(props);

        this.imageView = this.imageView.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeOutside = this.onChangeOutside.bind(this);
        this.onChangeVideo = this.onChangeVideo.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeMeal = this.onChangeMeal.bind(this);
        this.onChangeCuisine = this.onChangeCuisine.bind(this);
        this.onChangeSteps = this.onChangeSteps.bind(this);
        this.onChangeIngreds = this.onChangeIngreds.bind(this);
        this.onChangeServing = this.onChangeServing.bind(this);
        this.onChangeCal = this.onChangeCal.bind(this);
        this.hide = this.hide.bind(this);
        this.delete = this.delete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        // this.showNotifications = this.showNotifications.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.state = {
            name: '',
            description: '',
            outsideLink: [],
            videoLink: [],
            time: 0,
            hide: false,
            image: '',
            meal: 0,
            cuisine: '',
            steps: '',
            ingreds: '',
            serving: '',
            cal: 0
        }
    }

    componentDidMount() {
        //right before displays component
        axios.get("https://adits-notesapp.herokuapp.com/recipie/recipie/" + this.props.match.params.id)
            .then(response => {
                this.setState({
                    name: response.data.name,
                    meal: response.data.meal,
                    cuisine: response.data.cuisine,    
                    hide: response.data.hide,
                    image: response.data.image,
                    time: response.data.time,
                    steps: response.data.steps.join("; "),
                    ingreds: response.data.ingreds.join("; "),
                    outsideLink: response.data.outsideLinks.join("; "),
                    videoLink: response.data.videoLinks.join("; "),
                    serving: response.data.serving,
                    cal: response.data.cal,
                    description: response.data.description,
                });
                console.log(this.state.image);
            })
            .catch(function (error) {
                console.log(error);
            })
        
    }
    imageView(){
        if((new Buffer.from(this.state.image).toString("base64")) !=="")
            return <img src={"data:image/jpeg;base64,"+(new Buffer.from(this.state.image).toString("base64"))}  height="200"  alt="recipie"/>
    }
    
    hide(e) {
            this.setState({
                hide: !this.state.hide 
            });
    }

    delete(e) {
        axios.get("https://adits-notesapp.herokuapp.com/recipie/delete/" + this.props.match.params.id)
        .then(res => setTimeout(() => { console.log(res.data); window.location = "/admin/recipies"; }, 250));
}

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    onChangeDescription(e) {
        this.setState({
            description: e.target.value
        });
    }

    onChangeOutside(e) {
        this.setState({
            outsideLink: e.target.value
        });
    }

    onChangeVideo(e) {
        this.setState({
            videoLink: e.target.value
        });
    }

    onChangeTime(e) {
        this.setState({
            time: e.target.value
        });
    }

    onChangeMeal(e) {
        this.setState({
            meal: e.target.value
        });
    }

    onChangeCuisine(e) {
        this.setState({
            cuisine: e.target.value
        });
    }

    onChangeSteps(e) {
        this.setState({
            steps: e.target.value
        });
    }

    onChangeIngreds(e) {
        this.setState({
            ingreds: e.target.value
        });
    }

    onChangeServing(e) {
        this.setState({
            serving: e.target.value
        });
    }

    onChangeCal(e) {
        this.setState({
            cal: e.target.value
        });
    }
    onSubmit(e) {
        e.preventDefault();
        const recipie = {
            owner: localStorage.getItem('token'),
            name: this.state.name,
            meal: this.state.meal,
            cuisine: this.state.cuisine,    
            hide: this.state.hide,
            time: this.state.time,
            steps: this.state.steps.split("; "),
            ingreds: this.state.ingreds.split("; "),
            outsideLinks: this.state.outsideLink.split("; "),
            videoLinks: this.state.videoLink.split("; "),
            serving: this.state.serving,
            cal: this.state.cal,
            description: this.state.description,
        };

        axios.post("https://adits-notesapp.herokuapp.com/recipie/update/" + this.props.match.params.id, recipie)
        .then(res => setTimeout(() => { window.location = "/admin/recipies"; }, 250));
        // this.showNotifications();
    }

    onImageChange = event => {
        if (event.target.files && event.target.files[0]) {
          let img = event.target.files[0];
          
        const formData = new FormData();
        formData.append('file', img);
        axios.post("https://adits-notesapp.herokuapp.com/recipie/uploadphoto/adit/" + this.props.match.params.id, formData, { headers: {
            'Content-Type': 'multipart/form-data'
        }})
        .then(res => setTimeout(() => { console.log(res.data); }, 250));
          console.log(img);
          this.setState({
            image: URL.createObjectURL(img)
          });
        }
      };

    render() {
        return (
            <div>
            <title>Edit Recipie</title>
                <form onSubmit={this.onSubmit}>
                <div className="form-group">
                        <input
                            type="submit"
                            value="Edit Recipie"
                            className="editsubmit"
                            onChange={this.onSubmit}
                        />
                    </div>


                    <div className="form-group">
                        <textarea className= "topic" rows="1"
                                    type="text"
                                    value={this.state.name}
                            onChange={this.onChangeName}
                                />
                    </div>
                    <div className="form-group">
                    <div className="lblsep">Description :</div>
                    <textarea className= "description" rows="2"
                                    type="text"
                                    placeholder="Description"
                                value={this.state.description}
                               onChange={this.onChangeDescription}
                                />
                    
                    <div className="lblsep">Cuisine :</div>
                    <input className="rinput" type="text"  value={this.state.cuisine}
                               onChange={this.onChangeCuisine}></input>
                    <div className="lblsep">Serving :</div>
                    <input className="rinput" type="text"  value={this.state.serving}
                               onChange={this.onChangeServing}></input>
                    </div>
                    {this.imageView()}
                    <label className="bt1 short" for="myfile"></label>
                    <input id="myfile" className="bt1" type="file" name="myImage" onChange={this.onImageChange} style={{'background-color':'gold',color:'gold'}}/>
                    <div className="form-group">
                    <div className="lblsep">Ingredients :</div>

                    <textarea className= "description" rows="3"
                                    type="text"
                                    placeholder="Ingredients"
                                value={this.state.ingreds}
                               onChange={this.onChangeIngreds}
                                />
                    
                    <div className="lblsep">Instructions :</div>
                    <textarea className= "description" rows="3"
                                    type="text"
                                    placeholder="Instructions"
                                value={this.state.steps}
                               onChange={this.onChangeSteps}
                                />
                    </div>
                    <div className="lblsep">Links :</div>
                    <textarea className= "description" rows="3"
                                    type="text"
                                    placeholder="Links"
                                value={this.state.outsideLink}
                               onChange={this.onChangeOutside}
                                />
                    <div className="lblsep">Videos :</div>
                    <textarea className= "description" rows="3"
                                    type="text"
                                    placeholder="Videos"
                                value={this.state.videoLink}
                               onChange={this.onChangeVideo}
                                />
                    <div>
                        <label className="ocrlbl"> Time (min): </label>
                        <input
                            type="number"
                            className="ocurr"
                            value={this.state.time}
                             onChange={this.onChangeTime}
                        />
                    </div>
                    <div>
                        <label className="mealbl"> Meal :</label>
                        <input
                            type="number"
                            className="meal"
                            value={this.state.meal}
                             onChange={this.onChangeMeal}
                        />
                        <div className="meald">{this.state.meal.toString().replace("0","All").replace("1","Sauces/Preps").replace("2","Apps").replace("3", "Meal").replace("4","Dessert")}</div>
                    </div>
                    <div>
                        <label className="calbl"> Cal :</label>
                        <input
                            type="number"
                            className="cal"
                            value={this.state.cal}
                             onChange={this.onChangeCal}
                        />
                        <div className="meald">{this.state.meal.toString().replace("0","All").replace("1","Sauces/Preps").replace("2","Apps").replace("3", "Meal").replace("4","Dessert")}</div>
                    </div>
                    <button className="bt2 archive" onClick={() => { this.hide() }}>{this.state.hide.toString().replace("false","Hide").replace("true","Unhide")}</button>
                    <input type="button" style={{color:"red", "border-color":"red"}} className="bt1 complete" value="Delete" onClick={this.delete}/>

                    {/* <div className="form">

<label className="radiohead">Complete </label>
<div className="radio">
    <label className="radioparty">
        <input className="radioparty" type="radio" value="false" checked={this.state.complete === false} onChange={this.onChangeComplete} />
False/
</label>
    <label className="radioparty">
        <input className="radioparty" type="radio" value="true" checked={this.state.complete === true} display="false" onChange={this.onChangeComplete} />
True
</label>
</div>
</div>
                    */}
                </form> 
            </div>
        )
    }
}