import { Component } from 'react';
import axios from 'axios';
import "../all_blog/blog.css";
import Navbar from "../pers-navbar.component";
import { Link } from 'react-router-dom';

const Recipie = props => (
    <tr className="recipie">
        <td style={{backgroundColor:"transparent"}}><i style={{color:"grey"}}>{props.recipie.meal.toString().replace("3","Main").replace("1","Preps/Sauces").replace("2","Apps").replace("4","Dessert")} / </i> <Link className="rentry" to={{
      pathname: '/recipies/view/'+props.recipie._id+"/"+props.recipie.name.replace(/ /g,"-"), state: {id: props.recipie._id}}}>{props.recipie.name}</Link><div className="rdescript">{props.recipie.description}</div></td>
    </tr>
)
// +"/"+props.recipie.name.replace(/ /g, '_')
export default class BlogPosts extends Component {
    constructor(props) {
        super(props);
        // this.changeAlbum = this.changeAlbum.bind(this);
        this.pullRecipies = this.pullRecipies.bind(this);
        this.onChangeSearch = this.onChangeSearch.bind(this);
        this.state = {
            posts: [],
            album: "Personal",
            lastevent: "",
            search: ""
        }

    }

    componentDidMount(){
        this.pullRecipies(this.state.search);
    }

    recipieList() {
        return this.state.posts.map(currentrecipie => {
            return <Recipie recipie={currentrecipie} hide={this.hide} key={currentrecipie._id} />
        });
    }


    pullRecipies(search){
        if(search === ""){
        axios.get("https://adits-notesapp.herokuapp.com/recipie/")
                .then(response => {
                    this.setState({ posts: response.data.sort((a, b) => {
                        return a.meal - b.meal;
                    })
                });
                    // console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                  //  window.location = "/access";
                });
        }
        else {
            axios.get("https://adits-notesapp.herokuapp.com/recipie/search/"+search)
            .then(response => {
                this.setState({ posts: response.data });
                // console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
              //  window.location = "/access";
            });
    }   
        
    }
        
    onChangeSearch(e) {
        this.setState({
            search: e.target.value
        });
        this.pullRecipies(e.target.value);
    }



    render() {
        return (
            <div className="container-fluid">         
             <Navbar />   
                <title>Adit's Recipies</title>
                <div className="recipietitle">Adit's Recipies</div>
                <div className="searchbar"> Search: <input className="searchbar" type="search" value={this.state.search}
                               onChange={this.onChangeSearch}></input> </div>

                <table className="table table-lg  table-hover rtbl">
                <tbody>
                {this.recipieList()}
</tbody>
</table>
            </div>
        )
    }
}