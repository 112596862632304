import { Component } from 'react';
import { Dropdown} from 'react-bootstrap';
import "../all/navbar.css"

export default class Navbar extends Component {
    render() {
        return (
            <Dropdown>
            <Dropdown.Toggle className="drop"/>
            <Dropdown.Menu className="dropmenu">
                <Dropdown.Item className= "dropcard" href="/admin/notes">Notes</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/allergies/allergieslist">Allergies</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/blogs/">Blog</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/recipies">Recipies</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/crm">CRM</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/loci">Loci</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/admin/texter">Reach</Dropdown.Item>
                <Dropdown.Item className= "dropcard" href="/">Home</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
        )
    }
}
