import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import VANTA from 'vanta/dist/vanta.clouds.min';

const Home = () => {
    const [topic, setTopic] = useState('');
    const [chauvihaar, setChauvihaar] = useState(new Date().toLocaleTimeString());
    const vantaRef = useRef(null);

    useEffect(() => {
        const initializeVanta = () => {
            if (vantaRef.current && !vantaRef.current.vantaInitialized) {
                vantaRef.current.vantaInitialized = true;
                vantaRef.current.vantaEffect = VANTA({
                    el: vantaRef.current,
                    mouseControls: true,
                    touchControls: true,
                    gyroControls: false,
                    minHeight: 200.00,
                    minWidth: 200.00,
                    cloudShadowColor: 0x1D3170,
                });
            }
        };

        initializeVanta();

        return () => {
            if (vantaRef.current && vantaRef.current.vantaEffect) {
                vantaRef.current.vantaEffect.destroy();
            }
        };
    }, [vantaRef]);

    const fetchSunsetTime = () => {
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            const { latitude, longitude } = coords;

            axios.get(`https://api.sunrise-sunset.org/json?lat=${latitude}&lng=${longitude}&date=today&formatted=0`)
                .then((response) => {
                    const sunset = new Date(response.data.results.sunset);
                    sunset.setMinutes(sunset.getMinutes() - 48);
                    setChauvihaar(sunset.toLocaleTimeString());
                })
                .catch((error) => {
                    console.error('Error fetching sunset time:', error);
                });
        });
    };

    const handleTopicChange = (e) => {
        setTopic(e.target.value);
    };

    const submitNote = () => {
        const note = {
            topic: `${topic} / from a Guest`,
            date: new Date(),
            owner: "5ff152b6cee104066006d600",
            archive: false,
        };

        axios.post("https://adits-notesapp.herokuapp.com/admin/notes/add", note)
            .then(res => {
                setTimeout(() => {
                    console.log(res.data);
                }, 250);
            });

        setTopic('');
    };

    const renderLink = (path, label, className) => (
        <div className={className} title={`Click to see ${label}!`}>
            <a href={path}> <img className={`${className}i img-fluid`} align="middle" alt="" />
                <h3 className={`${className}t`}> {label} </h3>
            </a>
        </div>
    );

    return (
        <div className="container-fluid px-0" ref={vantaRef} style={{ "backgroundSize": "cover", height: "100vh" }}>
            <title>Home</title>
            <a href="/access"><button className="hiddenbutton">Admin Access</button></a>
            <h2 className="title"> Welcome to <span className="span2">Adit Mehta</span> (dot) com </h2>
            <div style={{ textAlign: 'right', color: 'white' }} onClick={fetchSunsetTime}>{chauvihaar}</div>
            <div className="container-fluid px-0">
                {renderLink("/professional", "Professional", "pro")}
                {renderLink("/recipies", "Personal", "per")}
            </div>
            <div className="sender">
                <input
                    className="newnote sendinput"
                    type="text"
                    placeholder="Send Adit a Note!"
                    value={topic}
                    onChange={handleTopicChange}
                />
                <input
                    type="submit"
                    value="Send"
                    className="bt2 sendbt"
                    onClick={submitNote}
                />
            </div>
        </div>
    );
};

export default Home;
